<template>
  <div class="home">

    <van-swipe class="swipe" :autoplay="5000" lazy-render>
      <van-swipe-item v-for="image in state.images" :key="image">
        <van-image width="100%" height="100%" fit="fill" :src="image"/>
      </van-swipe-item>
    </van-swipe>

    <div class="navbtn">

      <van-notice-bar class="notice" left-icon="volume-o" scrollable :text="config.notice ? config.notice.value: ''" v-show="config.notice && config.notice.value.length > 5"></van-notice-bar>

      <van-grid class="toolbar" column-num="4" :border="false">
        <van-grid-item icon="gem-o" :style="{color: '#F90'}" text="量化交易" to="/quantize" />
        <van-grid-item icon="after-sale"  :style="{color: '#090'}" text="自动建仓" to="/entrust" />
        <van-grid-item icon="chart-trending-o" :style="{color: '#F66'}" text="原理介绍"  to="/intro"/>
        <van-grid-item icon="youzan-shield" :style="{color: '#69F'}" text="新手指南"  to="/guide"/>
      </van-grid>

      <van-row class="help" gutter="5" align="center" justify="space-around">
        <van-col class="icon" span="12">
          <van-button block size="small" icon="cluster" icon-position="left" color="linear-gradient(45deg, #FF6, #F66 60%, #66F)" to="/invite">邀请赚钱</van-button>
        </van-col>
        <van-col class="icon" span="12">
          <van-button block size="small" icon="share-o" icon-position="right" color="linear-gradient(45deg, #66F, #F6F 60%, #FF6)" to="/my/exchange">我要使用</van-button>
        </van-col>
      </van-row>
    
    </div>

    <van-row class="block" align="top" justify="space-around" @click="refreshMarket('btcusdt') & refreshMarket('ethusdt')">
      <van-col class="title" span="24">
        <van-icon class="icon" name="exchange" color="'#666'" :style="{transform:'rotate(90deg)'}"/>
        <span>币价走势</span>
      </van-col>
      <van-col class="symbol" span="8">
        BTC / USDT
      </van-col>
      <van-col class="rate" span="4">
        <van-tag round text-color="#FFF" :color="color1">{{state.btc.rate}} %</van-tag>
      </van-col>
      <van-col class="symbol" span="8">
        ETH / USDT
      </van-col>
      <van-col class="rate" span="4">
        <van-tag round text-color="#FFF" :color="color2">{{state.eth.rate}} %</van-tag>
      </van-col>
      <van-col class="price" span="12">
        <number-roll :number="state.btc.price" :fg="color1" size="16"><template #prepend>$</template></number-roll>
      </van-col>
      <van-col class="price" span="12">
        <number-roll :number="state.eth.price" :fg="color2" size="16"><template #prepend>$</template></number-roll>
      </van-col>
    </van-row>

    <van-row class="block" gutter="2" align="top" justify="space-around" @click="refreshRecent">
      <van-col class="title" span="24">
        <van-icon class="icon" name="exchange" color="'#666'" />交易动态
      </van-col>
      <van-col class="load" span="24" v-show="state.list.length == 0"><van-loading type="spinner" /></van-col>
      <van-col class="list" span="24" v-for="(item,index) in state.list" :key="index">
        <van-cell-group :border="false">
          <van-cell class="dt" :title="item.dt" :value="item.type" />
          <van-cell class="item" icon-prefix="icon-symbol" :icon="item.symbol.toLowerCase()"  :border="false" :title="item.symbol.toUpperCase() + ' / USDT'" :label="item.info"/>
        </van-cell-group>
      </van-col>
    </van-row>

    <div class="copyright">Powered by 火神量化机器人</div>

  </div>
</template>

<style scoped>
.home {
  min-height: 600px;
  padding-bottom: 30px;
  display: block;
}

.swipe {
  width: 100%;
  height: 180px;
}

.navbtn {
  padding: 1px 5px;
  background: linear-gradient(180deg, #33F, #F6F 50%,  #FF9 80%, #FFF);
}

.notice {
  margin:3px 0 5px 0;
  height: 30px;
  background: #FFF;
  border-radius: 20px;
  color: #1989fa;
}

.toolbar {
  background: #FFF;
  border-radius: 20px 20px 0 0;
  border:solid 1px #FFF;
  overflow: hidden;
}

.help {
  padding: 0px 20px 10px 20px;
  background: #FFF;
}

.block {
  min-height: 120px;
  margin: 10px 5px;
  background: #FFF;
  border:solid 1px #EEE;
  border-radius: 5px;
}
.block .title {
  height: 40px; 
  background: linear-gradient(45deg, #F6F6F6, #FFF);
  color: #666;
  font-size: 12px; 
  line-height: 40px;
  text-align: left;
  overflow: hidden;
}
.block .icon {
  position: relative;
  top: 3px;
  width: 30px;
  color: #1989fa;
  font-size: 18px;
  text-align: center;
}
.block .load{
  min-height: 60px;
  text-align: center;
}

.symbol {
  padding: 10px 10px 0 10px;
  color: #666;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}
.rate {
  text-align: left;
}
.price {
  font-weight: 600;
  text-align: center;
}

.load {
  margin-top: 40px;
}
.list {
  text-align: left;
  display: inline-block;
}
.list:nth-child(even) .van-cell-group {
  background: #F9F9F9 !important;
}
.dt {
  padding: 10px 0 3px 20px;
  background: transparent;
  color: #999;
  font-size: 12px;
}
.item {
  padding-bottom: 20px;
  background: transparent;
  line-height: 28px;
}

.copyright {
  margin: 30px 0px;
  color: #CCC;
  font-size: 12px;
  text-align: center;
}
</style>

<script>
import {reactive} from 'vue'
import {mapState, mapGetters} from 'vuex'
import NumberRoll from '@/components/NumberRoll.vue'

export default {
  name: 'Home',
  components: { NumberRoll },

  setup() {
    const state = reactive({
      btc: {
        price: 0,
        rate: 0,
      },
      eth: {
        price: 0,
        rate: 0,
      },
      images: [
        require('../assets/slide.jpg'),
      ],
      list: []
    });
    return { 
      state
    };
  },

  created() {
    this.state.images = this.config.banner.value.split(",");
    this.refreshMarket("btcusdt");
    this.refreshMarket("ethusdt");
    this.refreshRecent();
  },

  computed: {
    color1() {
      return this.state.btc.rate >= 0 ? "#090" : "#F66";
    },
    color2() {
      return this.state.eth.rate >= 0 ? "#090" : "#F66";
    },
    ...mapState(["config", "profile"]),
    ...mapGetters(["authorized"]),
  },

  methods: {
    refreshMarket(symbol) {
      this.$http.get("/exchange/market?symbol=" + symbol).then((data) => this.state[symbol.replace(/usdt$/, "")] = data);
    },
    refreshRecent(){
      this.state.list = [];
      this.$http.get("/exchange/recent?limit=10").then((data) => {
        for (let i in data){
          let item = data[i];
          this.state.list.push({
            symbol: item.symbol.replace(/usdt$/, ""),
            type: "「" + ["清仓", "建仓", "加仓", "补仓"][item.type] + "」",
            info:  "$" + item.amount.replace(/(\.\d\d)(\d+)$/, "$1") + " / $" + item.price,
            dt: item.dt,
          });
        }
      });
    },
  }

}
</script>
